import request from '@/utils/request';
//获取appId
export function wcQrcodeSilenceAuth(data) {
  return request({
    url: '/api/wcQrcodeSilenceAuth',
    method: 'post',
    data
  });
}

//微信支付接口
export function fyCashierWxPay(data) {
  return request({
    url: '/api/fyCashierWxPay',
    method: 'post',
    data
  });
}

//获取appId  新版
export function WxGzhSilenceAuto(data) {
  return request({
    url: '/api/WxGzhSilenceAuto',
    method: 'post',
    data
  });
}

//微信支付接口  新版
export function WxGzhPay(data) {
  return request({
    url: '/api/WxGzhPay',
    method: 'post',
    data
  });
}

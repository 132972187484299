<template>
  <div class="kefu">
    <img src="@/assets/imgs/3.jpg" class="pic" alt="">
    <serviceCom />
  </div>
</template>

<script>
import serviceCom from '@/components/service.vue';
export default {
  name: 'kefu',
  components: {serviceCom},
  data() {
    
  },
  mounted() {
  },

  methods: {

  },
};
</script>
<style scoped lang="less">
.kefu{
    width: 100%;
    height: 100vh;
    background: #fff;
    .pic{
      width: 100%;
      background-size: 100%;
      display: block;
    }
}
</style>
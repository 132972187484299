<template>
  <div class="point" ref="point"
    :style="`width:${divWidth}px;height:${divHeight}px;right:${moveLeft}px;bottom:${moveTop}px;`"
    @touchstart.stop="(e) => { dragStart(e) }" @touchend="(e) => { dragEnd(e) }" @touchmove="(e) => dragMove(e)"
    @click.stop="toGo">
    <p class="txt">在线<br />客服</p>
  </div>
</template>

<script>
export default {
  name: 'service',
  data() {
    return {
      border_distance: 10,//可拖拽元素距离屏幕边界最小距离
      divWidth: 70,//可拖拽元素宽度
      divHeight: 70,//可拖拽元素高度
      moveLeft: 0,//可拖拽元素距离屏幕左边界
      moveTop: 300,//可拖拽元素距离屏幕上边界
      clientWidth: 0,//屏幕宽度
      clientHeight: 0//屏幕高度
    };
  },

  mounted() {

  },

  methods: {
    dragStart(e) {
      this.$refs.point.style.transition = "none";
    },
    dragEnd(e) {
      this.$refs.point.style.transition = 'all 0.3s';
      if (this.moveLeft > this.clientWidth / 2) {//若移动位于屏幕的左边或右边，则自动贴合左边或右边（此根据业务具体需求表现而定）
        this.moveLeft = 10;
      } else {
        this.moveLeft = 10;
      }
    },
    dragMove(e) {
      if (e.touches.length) {
        let touch = e.touches[0];
        // this.moveLeft = touch.clientX - this.divWidth / 2;//因拖动的中间点为Dom节点的中心点，所以left需减去Dom节点一半的宽度
        // this.moveTop = touch.clientY - this.divHeight / 2;//top原理同上
        this.moveLeft = window.innerWidth - touch.clientX - this.divWidth / 2; // 右边距离 = 屏幕宽度 - 当前触摸位置 - 元素宽度的一半
        // this.moveTop = window.innerHeight - touch.clientY - this.divHeight / 2; // 下边距离 = 屏幕高度 - 当前触摸位置 - 元素高度的一半
        // 计算移动位置，确保元素不超出屏幕
        this.moveTop = window.innerHeight - touch.clientY - this.divHeight / 2;

        // 确保元素的顶部不会超出屏幕顶部
        this.moveTop = Math.max(0, this.moveTop);

        // 确保元素的底部不会超出屏幕底部
        this.moveTop = Math.min(window.innerHeight - this.divHeight, this.moveTop)
      }
    },
    toGo() {
      this.$router.push('/kefu')
    }
  },
};
</script>
<style scoped lang="less">
.point {
  background-color: rgb(121.3, 187.1, 255);
  border-radius: 50%;
  position: absolute;
  box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.1);
  /* 外圈阴影 */
  text-align: center;
  z-index: 9999;
  
  .txt {
    padding-top: 30px;
    color: #fff;
    font-size: 1.2rem;
    padding-top: .9rem;
  }
}
</style>
<template>
  <div class="kefu">
    <iframe :src="iframeSrc" width="100%" height="100%" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  name: 'kefu',
  data() {
    return {
        iframeSrc:'https://ccc-v2.aliyun.com/v-chat?token=9XYGTGWtq2z_FMdLd8kywnBL8fArnfn8e_tGJgLgBvCYOJCWHAa8GGpiCbMqr2m1iPXqJF0XWwp2CucZdebaC9E-KkJJUDttMoZwFlxuMdg%3D'
    };
  },
  mounted() {
  },

  methods: {

  },
};
</script>
<style scoped lang="less">
.kefu{
    width: 100%;
    height: 100vh;
}
</style>
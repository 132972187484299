<template>
  <div class="wrap"><van-loading type="spinner" size="50" /></div>
</template>

<script>
import { getRandomRule } from '@/api/transfer';
export default {
  data() {
    return {};
  },
  async mounted() {
    // new VConsole();
    const { team, sales_id, username, conBrand } = this.$route.query;
    const result = await getRandomRule({
      team,
      sales_id,
      username,
      conBrand
    });
    const { pay_types, url } = result.data;
    //pay_types 1：跳转微信小程序  2：跳转h5页面
    if (+pay_types == 1) {
      window.location.href = url;
    } else {
      const { mchId } = this.getUrlParams(url);
      console.log('mchId', mchId);
      this.$router.push({
        path: '/wxCashDeskTest',
        query: {
          team,
          sales_id,
          username,
          conBrand,
          mchId
        }
      });
    }
  },
  methods: {
    getUrlParams(url) {
      const urlObj = new URL(url);
      const params = new URLSearchParams(urlObj.search);
      let paramValues = {};
      params.forEach((value, key) => {
        paramValues[key] = value;
      });
      return paramValues;
    }
  }
};
</script>
<style scoped lang="less">
.wrap {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
